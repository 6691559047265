<template>
    <div class="text-center">
        
        <v-row>
            <v-col 
                v-if="showButtons.cancel"
                style="text-align: right"
                cols="6"
                lg="6"
                md="6"
                sm="6"
            >
                <v-tooltip top>
                    <template v-slot:activator="{ on }">
                        <v-btn 
                            class="mx-2"
                            v-on="on"
                            @click="cancel"
                            style="background-color: var(--color__red) !important; color: #ffffff !important"
                        >
                            Cancel
                        </v-btn>
                    </template>
                    <span>Cancel</span>
                </v-tooltip>
            </v-col>
            <v-col 
                :style="showButtons.cancel ? 'text-align: left' : 'text-align: center'"
                :cols="showButtons.cancel ? '6' : '12'"
                :lg="showButtons.cancel ? '6' : '12'"
                :md="showButtons.cancel ? '6' : '12'"
                :sm="showButtons.cancel ? '6' : '12'"
            >
                <v-tooltip top>
                    <template v-slot:activator="{ on }">
                        <v-btn  
                            v-if="showButtons.save"
                            class="mx-2"
                            v-on="on"
                            :loading="loading"
                            @click="save(request)"
                        >
                            Save
                        </v-btn>
                    </template>
                    <span>Save</span>
                </v-tooltip>
            </v-col>
        </v-row>
    </div>
</template>

<script>

    export default {

        props: {
            request: {
                type: Object,
                default: null
            },

            showButtons: {
                type: Object,
                cancel: true,
                save: true
            },

            loading: {
                default: false
            }
        },

        methods: {

            cancel() {
                this.$emit("cancel");
            },

            save(request) {
                this.$emit("save", request);
            }
        }
    };
</script>

<style scoped>

    .v-btn {
        height: 45px !important;
        width: 150px;
    }

</style>
